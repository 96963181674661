
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PicConvocatoriaDatos } from './CrearFormulario.vue';

@Component
export default class CrearComponente extends Vue {
    @Prop() componente: PicConvocatoriaDatos;
    mostrar = false
    requerido = false
    texto_ayuda = ''
    mounted(){
    }
    guardarDatosComponete(){
        this.componente.texto_ayuda = this.texto_ayuda;
        this.componente.requerido = this.requerido
        this.mostrar = false;
    }
}
