
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component
export default class RegistrarPerfil extends Vue {
  perfil: string = "";

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        // min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {}

  RegistrarPerfil() {
    Axios()
      .post("almacenar_perfil", { perfil: this.perfil })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Perfil Creado!".toString().toUpperCase(),
            "Se a creado el perfil exitosamente",
            respuesta.data.status
          );
          this.$router.push({ name: "GestionarPerfil" });
        } else {
          Swal.fire(
            (respuesta.data.status + "!").toString().toUpperCase(),
            respuesta.data.message.perfil[0],
            respuesta.data.status
          );
        }
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  public validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.RegistrarPerfil();
      }
    });
  }
}
