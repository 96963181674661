
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component({
  components: {},
})
export default class ModificarEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;

  datosEducacion = {
    actualizado: "",
    certificado: "",
    creado: "",
    fecha_fin: "",
    fecha_inicio: "",
    id_educacion: "",
    institucion: "",
    persona_id: "",
    tipo_educacion: "",
    titulo_obtenido: "",
  };
  mounted() {
    // this.modificarDatosEducacion(this.$route.params.id);
    this.obtenerDatosEducacionPersona(this.$route.params.id);
    this.mostrarDocumento(this.datosEducacion.id_educacion);
  }
  vistaPreviaEvidencia() {
    if (this.docResolucon != "") {
      this.docVista = URL.createObjectURL(this.docResolucon);
    }
  }
  // voy a intentar mostrar en boton el pdf
  mirarModal() {
    this.verModal = true;
  }
  // ------------------------------------------------------------------------
  async obtenerDatosEducacionPersona(id) {
    await Axios()
      .get("ver_detalle_educacion_persona/" + id)
      .then((respuesta) => {
        this.datosEducacion = respuesta.data.results;
        this.mostrarDocumento(id);
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  //----------------------------------------------------------------
  mostrarDocumento(id_educacion) {
    this.rutadocumento =
      this.$urlAPI + "ver_certificado_educacion_persona/" +
      id_educacion;
  }

  modificarDatosEducacion(id) {
    this.datosEducacion.persona_id = this.id_persona_logeada;

    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("certificado", this.datosEducacion.certificado);
    data.append("fecha_fin", this.datosEducacion.fecha_fin);
    data.append("fecha_inicio", this.datosEducacion.fecha_inicio);
    data.append("institucion", this.datosEducacion.institucion);
    data.append("persona_id", this.id_persona_logeada);
    data.append("tipo_educacion", this.datosEducacion.tipo_educacion);
    data.append("titulo_obtenido", this.datosEducacion.titulo_obtenido);

    Axios()
      .post(
        "modificar_educacion_persona/" + this.datosEducacion.id_educacion,
        data,
        {
          headers: headers,
        }
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se subió la Educación!",
            "Educación Modificada",
            respuesta.data.status
          );
          this.estadoPeticion = false;
          this.$router.push({ name: "GestionarHojaDeVidaENI" });
        } else {
          Swal.fire("ERROR!", "No se Modifico La Educación", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarDatosEducacion(this.$route.params.id);
      }
    });
  }
  //-----------------------------------------------------------
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
  //--------------------------------------------------------------
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.datosEducacion.certificado = event.target.files[0];
        Swal.fire(
          "Resolución Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
      } else {
        this.docValido = false;
        this.datosEducacion.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.datosEducacion.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
}
