
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import Axios from "@/Axios";

@Component({
  components: {},
})
export default class Index extends Vue {
  programaColegio: any[] = [];

  mounted() {
    this.obtenerColegioPrograma();
  }

  obtenerColegioPrograma() {
    Axios()
      .get("/listar_colegio_programa")
      .then((respuesta) => {
        this.programaColegio = respuesta.data.results;

        if (respuesta.data.status == "success") {
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {});
  }
}
