
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component({
  components: {},
})
export default class FormularioPersonal extends Vue {
  discapacidad = {
    tiene: "",
    noTiene: "",
  };
  lista_pais: any[] = [];
  lista_discacidades: any[] = [];
  lista_grupose: any[] = [];
  lista_departamentos = null;
  lista_municipios = null;
  departamentoActual = null;
  departamentoResidencia = null;
  departamentoNacimiento = null;

  areas: any[] = [];
  picTipos: any[] = [];
  redesConocimiento: any[] = [];
  capacitacion = {
    id_pic: null,
    capacitacion: "",
    pertenece_a_red: "",
    plan_institucional_id: "",
    id_area_ruta: "",
    id_pic_tipo: "",
  };
  requisito = "";
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  datosPersonales = {
    correo: "",
    correo_aternativo: "",
    depto_expedicion: "",
    depto_nacimiento: "",
    depto_residencia: "",
    direccion: "",
    discapacidad: "",
    id_discapacidad: "",
    fecha_identificacion: "",
    fecha_nacimiento: "",
    genero: "",
    id_depto_expedicion: "",
    id_depto_nacimiento: "",
    id_depto_residencia: "",
    id_genero: "",
    id_municipio_expedicion: "",
    id_municipio_nacimiento: "",
    id_municipio_residencia: "",
    id_persona: "",
    id_tipo_identificacion: "",
    identificacion: "",
    localidad: "",
    municipio_expedicion: "",
    municipio_nacimiento: "",
    municipio_residencia: "",
    pais: "",
    pais_nacimiento: "",
    pais_residencia: "",
    porcentaje_discapacidad: "",
    primer_apellido: "",
    primer_nombre: "",
    segundo_apellido: "",
    segundo_nombre: "",
    telefono_fijo: "",
    telefono_movil: "",
    id_grupo_etnico: "",
    grupo_etnico: "",
    id_pais: "",
  };

  mounted() {
    this.obtenerDepartamentos();
    this.obtenerPaises();
    this.obtenerDatosPersonales(this.id_persona_logeada);
    this.obtenerDiscacidades();
    this.obtenergrupos();
    // this.obtenerMunicipios(54);
  }

  obtenerDiscacidades() {
    Axios()
      .get("crear_datos_personales_persona")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_discacidades = respuesta.data.results.discapacidades;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  obtenergrupos() {
    Axios()
      .get("listar_grupos_etnicos")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_grupose = respuesta.data.results;
         
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  // paises,departamentos y municipios datos personales #1

  obtenerDepartamentos() {
    Axios()
      .get("listar_departamentos/57")
      .then((respuesta) => {
        this.lista_departamentos = respuesta.data.results;
      })
      .catch((err) => {
        Swal.fire({
          title: "Se ha presentado un error en el servidor!",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "#238276",
          },
        });
      });
  }

  obtenerMunicipios(iddepa) {
    Axios()
      .get("listar_municipios/" + iddepa)
      .then((respuesta) => {
        this.lista_municipios = respuesta.data.results.municipios;
      })
      .catch((err) => {
        Swal.fire({
          title: "Se ha presentado un error en el servidor!",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "#238276",
          },
        });
      });
  }

  obtenerPaises() {
    Axios()
      .get("/listar_paises")
      .then((respuesta) => {
        this.lista_pais = respuesta.data.results;
        console.log("hubh",this.lista_pais);

      })
      .catch((err) => {
        Swal.fire({
          title: "Se ha presentado un error en el servidor!",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "#238276",
          },
        });
      });
  }

  //-----------------------------------------------------------

  obtenerDatosPersonales(id) {
    Axios()
      .get("ver_detalle_persona/" + id)
      .then((respuesta) => {
        return respuesta;
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.datosPersonales = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  metodono(){
    this.datosPersonales.discapacidad = null
    this.datosPersonales.id_discapacidad = null
    this.datosPersonales.porcentaje_discapacidad = null
    
  }

  modificarDatosPersonales(id) {
    Axios()
      .put("modificar_datos_persona/" + id, this.datosPersonales)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Datos personales modificados",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarHojaDeVidaENI" });
        } else {
          Swal.fire({
            title: "Datos personales No modificados",
            icon: "error",
            // text: respuesta.data.message.toString(),
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        max_value: () => "El valor no puede ser mayor a cien",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite números ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        // this.crearPIC();
      }
    });
  }
}
