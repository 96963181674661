
  import { Vue } from "vue-property-decorator";
  import Component from "vue-class-component";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";

  @Component({
    components: {
    },
  })
  export default class Alistamiento extends Vue {
    inactivo = false;

    docAlistamiento = {
      convenio_id: "",
      colegio_id: "",
      autodiagnostico_id: "",
      novedad_id: "",
      acta_compromiso_id: "",
      articulacion_curriculo_id: "",
    }

    mounted() {
      this.docAlistamiento.convenio_id = this.$route.params.id_convenio;
      this.docAlistamiento.colegio_id = this.$route.params.id_colegio;
    }
   
  }
