var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-lg-8 col-md-10 col-sm-12 offset-lg-3 offset-md-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-6"},[_vm._m(1),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.programasProye[0].programa_formacion))])])]),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_vm._m(2),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.programasProye[0].centro_formacion_id))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-6"},[_vm._m(3),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.programasProye[0].colegio_cargo))])])]),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_vm._m(4),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.programasProye[0].sena_cargo))])])])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('router-link',{staticClass:"btn btn-block bg-azul-sena text-white",attrs:{"to":{
              name: 'GestionarProgramasAprobados',
            }}},[_vm._v(" Volver ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("VER PROGRAMA")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Programa Formación: ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Centro Formacion: ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Colegio cargo: ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Sena cargo: ")])])
}]

export { render, staticRenderFns }