
import Axios from "@/Axios";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import BarChart from "@/components/Charts/BarChart";
import Chart from "chart.js";
import AnimacionTablasCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionTablasCargando,
    BarChart,
  },
})
export default class listaAdjudicados extends Vue {
  canvas = document.getElementsByTagName("canvas");
  verGraficas = false;
  año: any[] = [];
  listaCentros = [];
  listaRegionales = [];
  adjudicados: any[] = [];
  listaFases = [];
  AdjudicadosFiltrado: any = [];
  paginate = ["AdjudicadosFiltrado"];
  filterRegional = null;
  filterCentro = null;
  filterFase = "";
  contConvCreacion = 0;
  contConvFinalizada = 0;
  contConvCancelacion = 0;
  contConvEjecucion = 0;
  contConvAdjudicacion = 0;
  contConvInscripcion = 0;
  contConvCierre = 0;
  contApoyoSostenimientoF = 0;
  contApoyoSostenimientoR = 0;
  contRepresentantes = 0;
  contReporteActividades = 0;
  contMonitorias = 0;
  contInternados = 0;
  contApoyoTransporte = 0;
  contApoyoMedioTecnologico=0;
  contApoyoAlimentacion = 0;
  id_regional = null;
  regional = null;
  estadoPeticionTabla = false;

  mounted() {
    // this.obtenerRegionales();
    this.obtenerAdjudicados(
      JSON.parse(sessionStorage.getItem("usuario")).regional_id
    );
    this.buscarCentrosSegunRegional(JSON.parse(sessionStorage.getItem("usuario")).regional_id)
  }
  // obtenerAdjudicados(id) {
  //   Axios()
  //     .get("reportes_adjudicados")
  //     .then((respuesta) => {
  //       if (respuesta.data.status == "success") {
  //         this.adjudicados = respuesta.data.results;
  //         this.AdjudicadosFiltrado = respuesta.data.results;
  //         this.año = respuesta.data.results;
  //         let arregloFiltrado = [];

  //         for (let conv of this.adjudicados) {
  //           let regional = conv.regional;
  //           if (
  //             regional.indexOf(
  //               JSON.parse(sessionStorage.getItem("usuario")).regional
  //             ) >= 0
  //           ) {
  //             arregloFiltrado.push(conv);
  //           }
  //           this.adjudicados = arregloFiltrado;
  //           this.AdjudicadosFiltrado = arregloFiltrado;
  //         }
  //         if (this.AdjudicadosFiltrado.length == 0) {
  //           //busco no encontro
  //           Swal.fire({
  //             title: "No hay coincidencias",
  //             position: "center",
  //             timer: 1000,
  //             text: "No se encontró convocatorias que coincidan con las busqueda",
  //             showConfirmButton: false,
  //             // confirmButtonText: "Aceptar",
  //             // confirmButtonColor: "#238276",
  //             backdrop: "rgba(0,0,0,0)",
  //             background: "#eeeeee",
  //           });
  //           this.regional = null;
  //           this.AdjudicadosFiltrado = this.adjudicados;
  //         }
  //         this.generarContadoresPorFase();
  //       } else {
  //         Swal.fire({
  //           title: "Hubo un error obteniendo las convocatorias",
  //           icon: respuesta.data.status,
  //           text: respuesta.data.message,
  //           confirmButtonText: "Aceptar",
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       Swal.fire(
  //         "ERROR!",
  //         "Se ha presentado un error en el servidor!",
  //         "error"
  //       );
  //     })
  //     .finally(() => {
  //       this.estadoPeticionTabla = true;
  //     });
  // }
  obtenerAdjudicados(id) {
    Axios()
      .get("reportes_adjudicados")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.adjudicados = respuesta.data.results;
          this.AdjudicadosFiltrado = respuesta.data.results;
          this.año = respuesta.data.results;
          this.generarContadoresPorFase();
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo las convocatorias",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  buscarCentrosSegunRegional(id_regional) {
    Axios()
      .get("listar_centro_formacion_regional/ " + id_regional)
      .then((respuesta) => {
        
        if (respuesta.data.status == "success") {
          this.listaCentros = respuesta.data.results;
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo las convocatorias",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  obtenerFases() {
    Axios()
      .get("crear_convocatoria")
      .then((respuesta) => {
        this.listaFases = respuesta.data.results.fases_bienestar;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  picFiltrado() {
    let arregloFiltrado = [];
    // this.listaConvocatoriasFiltrado = []

    for (let adj of this.adjudicados) {
      let regional = adj.regional;

      if (regional.indexOf(this.filterRegional.regional) >= 0) {
        arregloFiltrado.push(adj);
      }
    }

    this.AdjudicadosFiltrado = arregloFiltrado;
    if (this.AdjudicadosFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      // this.filter = "";
      this.AdjudicadosFiltrado = this.adjudicados;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }
  filtroCentro() {
    let arregloFiltrado = [];
    for (let conv of this.adjudicados) {
      let centro = conv.centro_formacion;
      if (centro.indexOf(this.filterCentro) >= 0) {
        arregloFiltrado.push(conv);
      }
      this.AdjudicadosFiltrado = arregloFiltrado;
    }
    if (this.AdjudicadosFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterCentro = null;
      this.AdjudicadosFiltrado = this.adjudicados;
    }
  }

  exportExcel() {
    let data = XLSX.utils.json_to_sheet(this.AdjudicadosFiltrado, {
      header: [
        "convocatoria",
        "regional",
        "centro_formacion",
        "tipo_apoyo",
        "fase_bienestar",
      ],
    });
    (data["A1"].v = "convocatoria"),
      (data["B1"].v = "regional"),
      (data["C1"].v = "centro formacion"),
      (data["D1"].v = "tipo apoyo"),
      (data["E1"].v = "fase bienestar");

    const workbook = XLSX.utils.book_new();
    const filename =
      "Reporte Convocatorias " +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1);
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }
  generarContadoresPorFase() {
    for (let i of this.adjudicados) {
      if (i.tipo_apoyo == "Apoyos de alimentación") {
        this.contApoyoAlimentacion = this.contApoyoAlimentacion + 1;
      } else if (i.tipo_apoyo == "Apoyos de transporte") {
        this.contApoyoTransporte = this.contApoyoTransporte + 1;
      } else if (i.tipo_apoyo == "Internados-Centros de convivencia") {
        this.contInternados = this.contInternados + 1;
      } else if (i.tipo_apoyo == "Monitorias") {
        this.contMonitorias = this.contMonitorias + 1;
      } else if (
        i.tipo_apoyo ==
        "Reporte de actividades de la política de enfoque pluralista y diferencial"
      ) {
        this.contReporteActividades = this.contReporteActividades + 1;
      } else if (i.tipo_apoyo == "Representantes de aprendices") {
        this.contRepresentantes = this.contRepresentantes + 1;
      } else if (i.tipo_apoyo == "Apoyos de sostenimiento Regular") {
        this.contApoyoSostenimientoR = this.contApoyoSostenimientoR + 1;
      } else if (i.tipo_apoyo == "Apoyos de sostenimiento FIC") {
        this.contApoyoSostenimientoF = this.contApoyoSostenimientoF + 1;
      }else if(i.tipo_apoyo == "Apoyos de medios Tecnológicos-Plan de datos"){
        this.contApoyoMedioTecnologico= this.contApoyoMedioTecnologico + 1;
      }

    }
    for (let i of this.adjudicados) {
      if (i.fase_bienestar == "Creación") {
        this.contConvCreacion = this.contConvCreacion + 1;
      }
      if (i.fase_bienestar == "Cancelación") {
        this.contConvCancelacion = this.contConvCancelacion + 1;
      }
      if (i.fase_bienestar == "Finalizada\n") {
        this.contConvFinalizada = this.contConvFinalizada + 1;
      } else if (i.fase_bienestar == "Ejecución") {
        this.contConvEjecucion = this.contConvEjecucion + 1;
      } else if (i.fase_bienestar == "Adjudicación") {
        this.contConvAdjudicacion = this.contConvAdjudicacion + 1;
      } else if (i.fase_bienestar == "Inscripción") {
        this.contConvInscripcion = this.contConvInscripcion + 1;
      } else if (i.fase_bienestar == "Cierre") {
        this.contConvCierre = this.contConvCierre + 1;
      }
    }
    this.graficas();
  }
  graficas() {
    new Chart(this.canvas[0], {
      type: "doughnut",
      data: {
        labels: [
          "Creación",
          "Cancelación",
          "Finalizada",
          "Ejecución",
          "Adjudicación",
          "Inscripción",
        ],
        datasets: [
          {
            label: "Fases",
            data: [
              this.contConvCreacion,
              this.contConvCancelacion,
              this.contConvFinalizada,
              this.contConvEjecucion,
              this.contConvAdjudicacion,
              this.contConvInscripcion,
            ],
            backgroundColor: [
            "#F2A633",
              "#33DDF2", 
              "#7DDB39",
             
              "#FF3F59",
              "#A64500",
              "#E99AE9",
            ],
            borderColor: [
            "rgb(255, 99, 132)",
              "#247EF5",  
              "#43A700",
              "#D53F59",
              "#804400",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
              "#E99AE9",
            ],
            borderWidth: 1,
          },
        ],
      },
    });
    new Chart(this.canvas[1], {
      type: "pie",
      data: {
        labels: [
          "Apoyos de alimentación",
          "Apoyos de transporte",
          "Internados-Centros de convivencia",
          "Monitorias",
          "Reporte de actividades de la política de enfoque pluralista y diferencial",
          "Representantes de aprendices",
          "Apoyos de sostenimiento Regular",
          "Apoyos de sostenimiento FIC",
          "Apoyos de medios Tecnológicos-Plan de datos",
        ],
        datasets: [
          {
            label: "Apoyos",
            data: [
              this.contApoyoAlimentacion,
              this.contApoyoTransporte,
              this.contInternados,
              this.contMonitorias,
              this.contReporteActividades,
              this.contRepresentantes,
              this.contApoyoSostenimientoR,
              this.contApoyoSostenimientoF,
              this.contApoyoMedioTecnologico,
            ],
            backgroundColor: [
            "#F2A633",
                "#33DDF2", 
                "#E99AE9",
                "#7DDB39",
                "#E9783C",
                "#E9BE3C",
                "#FF3F59",
                "#A64500",
                "rgba(153, 102, 255, 0.2)",
                "#FF3F59",
            ],
            borderColor: [
            "rgb(255, 99, 132)",
                "#247EF5",
                "#E99AE9",
                "#43A700",
                "#E9783C",
                "#E9BE3C",
                "#D53F59",
                "#804400",
                "rgb(153, 102, 255)",
                "#D53F59",
            ],
            borderWidth: 1,
          },
        ],
      },
    });
  }
  filtroFases() {
    let arregloFiltrado = [];

    for (let conv of this.adjudicados) {
      let fase = conv.fase_bienestar;
      if (fase.indexOf(this.filterFase) >= 0) {
        arregloFiltrado.push(conv);
      }
      this.AdjudicadosFiltrado = arregloFiltrado;
    }
    if (this.AdjudicadosFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterFase = "";
      this.AdjudicadosFiltrado = this.adjudicados;
    }
  }
}
