
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {},
})
export default class verProgramaProyectado extends Vue {
  estadoPeticion = false;
  programasProye = {
    centro_formacion_id: "",
    colegio_cargo: "",
    colegio_id: "",
    convenio_id: "",
    fase_id: "",
    fecha_final: "",
    fecha_firma: "",
    fecha_inicio: "",
    id_convenio_programa_proyectado: "",
    numero_convenio: "",
    programa_formacion: "",
    programa_formacion_id: "",
    regional_id: "",
    sena_cargo: "",
    sena_persona_id: "",
  };

  mounted() {
    this.obtenerProgramaProyectado(this.$route.params.id);
  }

  obtenerProgramaProyectado(id) {
    Axios()
      .get("ver_programa_proyectado/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.programasProye = respuesta.data.results;
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
}
