
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import ReportePaneCentro from "@/components/ReportesEgresados/ReportePaneCentro.vue";
import ReporteTrazabilidad from "@/components/ReportesEgresados/ReporteTrazabilidad.vue";

@Component({
  components: {
    ReportePaneCentro,
    ReporteTrazabilidad,
  },
})
export default class IndexReportes extends Vue {
  esReportePaneCentro = false;
  esReporteTrazabilidad = false;

  constructor() {
    super();
  }
  cambiarEstadosComponentes() {
    this.esReportePaneCentro = false;
    this.esReporteTrazabilidad = false;
  }
}
