var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("ALISTAMIENTO")]),_c('hr'),(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),_c('div',{staticClass:"table-responsive col-sm-12"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.colegiosConvenio),function(ColegioPro,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(ColegioPro.colegio)+" ")]),_c('td',[_vm._v(_vm._s(ColegioPro.convenio))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver ","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                        name: 'VerDetallePICEje',
                        params: {
                          id: ColegioPro.id_eje,
                        },
                      }}},[_c('i',{staticClass:"fas fa-eye"})])],1),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Modificar ","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                        name: 'ModificarPICEje',
                        params: {
                          id: ColegioPro.id_eje,
                        },
                      }}},[_c('i',{staticClass:"fas fa-edit"})])],1)])])])}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("colegio")]),_c('th',{staticClass:"col-sm-4",attrs:{"scope":"col"}},[_vm._v("convenio")]),_c('th',{staticClass:"col-sm-2",attrs:{"scope":"col"}},[_vm._v("Acciones")])])])
}]

export { render, staticRenderFns }