
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import ReportePANENaRegional from "@/components/ReportesEgresados/Regional/ReportePANERegional.vue";
import ReporteTrazabilidad from "@/components/ReportesEgresados/ReporteTrazabilidad.vue";

@Component({
  components: {
    ReportePANENaRegional,
    ReporteTrazabilidad,
  },
})
export default class IndexReportes extends Vue {
  esReportePaneNacional = false;
  esReporteTrazabilidad = false;

  constructor() {
    super();
  }
  cambiarEstadosComponentes() {
    this.esReportePaneNacional = false;
    this.esReporteTrazabilidad = false;
  }
}
