
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import Axios from "@/Axios";

@Component({
  components: {},
})
export default class RegistrarColegioPrograma extends Vue {
  estadoPeticion = false;
  colegiosConvenio = [];

  convenio = {
    id_convenio: "",
    convenio: "",
    regional_id: "",
    regional: "",
    centro_formacion_id: "",
    sena_persona_id: "",
    numero_convenio: "",
    fecha_firma: "",
    fecha_inicio: "",
    fecha_final: "",
    sena_cargo: "",
    colegio_cargo: "",
    minuta: null,
    municipio_id: "",
    am_convenio_colegio: [],
    // usuarioPersona: "",
  };

  mounted() {
    this.obtenerColegiosConvenio();
  }

  obtenerColegiosConvenio() {
    Axios()
      .get("listar_convenio_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.colegiosConvenio = respuesta.data.results;
          this.convenio = respuesta.data.results;
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
}
