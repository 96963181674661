
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component
export default class PermisosXModulo extends Vue {
  estadoPeticion = false;
  listaPerfiles = [];
  listaModulos = [];
  listaModulosPerfilesPermisos = [];
  constructor() {
    super();
  }

  mounted() {
    this.obtenerModulosPerfiles();
    this.obtenerPermisosPerfilModulo();
  }

  obtenerModulosPerfiles() {
    Axios()
      .get("listar_modulos_perfiles")
      .then((respuesta) => {
        this.listaPerfiles = respuesta.data.results.perfiles;
        const modulosCargados = respuesta.data.results.modulos;
        this.recorreArrary(modulosCargados);
      })
      .catch((error) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  activarCheck() {
    // this.listaModulosPerfilesPermisos.forEach((permiso) => {
    //   const idCheck = permiso.id_item_modulo + "." + permiso.id_perfil;

    //   // const check = document.getElementById(idCheck).querySelector("input");
    //   // if (check.type == "checkbox") {
    //   //   check.checked = false;
    //   // }

    //   const check = document.getElementById(idCheck).querySelector("input");
    //   if (check.type == "checkbox") {
    //     check.checked = false;
    //   }
    // });

    this.listaModulosPerfilesPermisos.forEach((permiso) => {
      const idCheck = permiso.id_item_modulo + "." + permiso.id_perfil;
      // document.getElementById(idCheck).checked = true;
      let checks = document.getElementsByTagName("input");
      for (let i = 0; i < checks.length; i++) {
        const element = checks[i];
        if (element.id == idCheck) {
          element.checked = true;
        }
      }

      // const pp =document.getElementsByName;

      // document.querySelector("input[id='"+idCheck+"']').
    });
  }

  async obtenerPermisosPerfilModulo() {
    await Axios()
      .get("listar_permisos_mod_per")
      .then((respuesta) => {
        this.listaModulosPerfilesPermisos = respuesta.data.results;
        return true;
      })
      .then((res) => {
        if (res) {
          setTimeout(() => {
            this.activarCheck();
          }, 100);
        }
      })
      .catch(() => {
        //  Swal.fire((respuesta.data.status+"!").toString().toUpperCase(), respuesta.data.message, respuesta.data.status);
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  recorreArrary(modulos, nivel = "", padding = 5) {
    modulos.forEach((modulo) => {
      modulo.padding = padding;
      modulo.item_modulo = nivel + modulo.item_modulo;
      this.listaModulos.push(modulo);
      if (modulo.hijos.length > 0) {
        this.recorreArrary(modulo.hijos, nivel + "", padding + 20);
      }
    });
  }

  asignarPermiso(modulo, pefi) {
    Axios()
      .post("almacenar_permisos_mod_per", {
        perfil_id: pefi,
        item_modulo_id: modulo,
      })
      .then((respuesta) => {
        // respuesta.data.results.perfiles;
        if (respuesta.data.status != "success") {
          Swal.fire(
            (respuesta.data.status + "!").toString().toUpperCase(),
            respuesta.data.message,
            respuesta.data.status
          );
        } else {
          // Swal.fire(
          //   (respuesta.data.status + "!").toString().toUpperCase(),
          //   respuesta.data.message,
          //   respuesta.data.status
          // );

          this.recargarMatrizCheckBox();
          this.$router.push({ name: "GestionarPermiso" });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor! aaa",
          "error"
        );
      });
    // /crear_permisos_mod_per
  }

  recargarMatrizCheckBox() {
    let checks = document.getElementsByTagName("input");
    for (let i = 0; i < checks.length; i++) {
      const element = checks[i];
      if (element.type == "checkbox") {
        element.checked = false;
      }
    }

    this.obtenerPermisosPerfilModulo();
  }
}
