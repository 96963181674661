

import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";


@Component
export default class VerPermisosPerfil extends Vue{

obtener_id = "";
lista = [];
    mounted() {
        this.obtener_id = this.$route.params.id;
        console.log(this.obtener_id);
        this.obtenerPermisosoPerfil();
    }

    obtenerPermisosoPerfil()
    {
        Axios()
        .get("mostrarpermisosperfil/" + this.$route.params.id)
        .then((reponse)=>
        {
            this.lista = reponse.data.results;
            console.log(this.lista);
        })
        .catch((cat)=>
    {
        console.log("la consulata no se realizao con exito " );
        
    });
    }

}

