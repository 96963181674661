
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator';
import CrearComponente from './CrearComponente.vue';

@Component({
    components: {
        CrearComponente
    }
})
export default class Crearformularios extends Vue {
    ver: any = false;
    crear: any = true;
    documento: any
    componentes: PicConvocatoriaDatos [] = []
    mounted(){
        if (this.$route.params.ver != undefined && this.$route.params.crear != undefined) {
        this.ver = this.$route.params.ver;
        this.crear = this.$route.params.crear;      
        }
    }
    agregarComponente(tipo: string){
        const componente: PicConvocatoriaDatos = new PicConvocatoriaDatos()
        componente.etiqueda = tipo
        componente.texto_ayuda = "nombre del dato"
        componente.nombre_dato = ""
        componente.requerido = false
        this.componentes.push(componente)
    }
}
export class PicConvocatoriaDatos {
    id: number = 0
    id_tipo_dato: number = 0
    pic_conv_id: number = 0
    etiqueda: string = ''
    texto_ayuda: string = ''
    nombre_dato: string = ''
    requerido: boolean = true
}
