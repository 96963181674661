
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Directorio extends Vue {
  lista: any = [];

  constructor() {
    super();
    this.lista = [
      {
        nombre: "Héctor Castaño Velásquez",
        telefono: 3196991562,
        ip: 13895,
        e_mail: "hcastano@sena.edu.co",
        rol: "PLANTA",
      },
      {
        nombre: "Karina Inés Altamiranda Rodriguez",
        telefono: 3214432077,
        ip: 12041,
        e_mail: "kaltamiranda@sena.edu.co",
        rol: "CONTRATISTA: APOYOS DE SOSTENIMIENTO",
      },
      {
        nombre: "Angela Andrea Alba Villamil",
        telefono: 3133887680,
        ip: 12595,
        e_mail: "aalbav@sena.edu.co",
        rol: "CONTRATISTA: APOYO EN EGRESADOS",
      },
      {
        nombre: "Dora Emma Ramirez Serafinoff",
        telefono: 3005611670,
        ip: 0,
        e_mail: "deramirez@sena.edu.co",
        rol: "PLANTA",
      },
      {
        nombre: "Francia Elena Arango Monsalve",
        telefono: 3138528163,
        ip: 13003,
        e_mail: "fearango@sena.edu.co",
        rol: "CONTRATISTA",
      },
      {
        nombre: "Yolanda Marín Maldonado",
        telefono: 3132571571,
        ip: 12136,
        e_mail: "ymarinm@sena.edu.co",
        rol: "PLANTA",
      },

      {
        nombre: "Johanna Andrea Sandoval Lorgia",
        telefono: 3177381786,
        ip: 12131,
        e_mail: "josandoval@sena.edu.co",
        rol: "CONTRATISTA: APOYO EN LIDERAZGO",
      },
      {
        nombre: "Claudia Forero Londoño",
        telefono: 3182191999,
        ip: 12301,
        e_mail: "cpforero@sena.edu.co",
        rol: "COORDINADORA GRUPO",
      },
      {
        nombre: "Carolina Perez Cardenas",
        telefono: 3132831412,
        ip: 0,
        e_mail: "cperez@sena.edu.co",
        rol: "CONTRATISTA",
      },
      {
        nombre: "Adriana Paola Forero Castañeda",
        telefono: 3166170141,
        ip: 0,
        e_mail: "adpforero@sena.edu.co",
        rol: "CONTRATISTA",
      },
    ];
  }
}
