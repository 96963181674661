
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component
export default class ModificarPerfil extends Vue {
  perfil = "";
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        // min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerPerfil(this.$route.params.id);
  }
  obtenerPerfil(id) {
    Axios()
      .get("/editar_perfil/" + id)
      .then((respuesta) => {
        this.perfil = respuesta.data.results.perfil;
      });
  }
  ModificarPerfiles() {
    Axios()
      .put("/actualizar_perfil/" + this.$route.params.id, {
        perfil: this.perfil,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Perfil Modificado!".toString().toUpperCase(),
            "Se a modificado el perfil exitosamente",
            respuesta.data.status
          );
          this.$router.push({ name: "GestionarPerfil" });
        } else {
          Swal.fire(
            (respuesta.data.status + "!").toString().toUpperCase(),
            respuesta.data.message.perfil[0],
            respuesta.data.status
          );
        }
      });
  }

  //validar formulario
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.ModificarPerfiles();
      }
    });
  }
}
