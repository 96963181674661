
import { Component, Vue } from "vue-property-decorator";
import component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@component({
  components: {},
})
export default class graficasNacionales extends Vue {
  listaRegionales: any[] = [];
  listaCentros: any[] = [];
  listaConvocatorias: any[] = [];

  convocatoriaInfo = {
    id_convocatoria : ""
  };



  mounted() {
    this.obtenerRegionales();
    this.obtenerListaConvocatorias();
    this.obtenerListaCentros();
    this.listarVistaActividades();
  }

  obtenerRegionales() {
    Axios()
      .get("listar_regional_convenio")
      .then((respuesta) => {
        this.listaRegionales = respuesta.data.results;
      });
  }
  obtenerListaConvocatorias() {
    Axios()
      .get(
        "listar_convocatorias/" +
          JSON.parse(sessionStorage.getItem("usuario")).id_usuario
      )
      .then((respuesta) => {
        this.listaConvocatorias = respuesta.data.results;
      });
  }
  obtenerListaCentros() {
    Axios()
      .get(
        "listar_centros_regional/" +
          JSON.parse(sessionStorage.getItem("usuario")).id_usuario
      )
      .then((respuesta) => {
        this.listaCentros = respuesta.data.results;
      });
  }
  convocatoria(){
    Axios()
    .get("listar_convocatoria/"+ this.convocatoriaInfo.id_convocatoria )
    .then((respuesta)=>{
    })
  }
  listarVistaActividades(){
    Axios()
    .get("listar_vista_actividades")
    .then((respuesta)=>{
    })
  }
}
