
import { Component, Vue } from 'vue-property-decorator';
import Slider from '../components/Slider.vue';
import QuienesSomos from './componentes-egresados/QuienesSomos.vue';
import Funciones from './componentes-egresados/Funciones.vue';
import EstructuraOrganizacional from './componentes-egresados/EstructuraOrganizacional.vue';
import Directorio from './componentes-egresados/Directorio.vue';
import Noticias from './componentes-egresados/Noticias.vue';
import Planeacion from './componentes-egresados/Planeacion.vue';
import Video from './componentes-egresados/Video.vue';

@Component({
  components: {
    Slider,
    QuienesSomos,
    Funciones,
    EstructuraOrganizacional,
    Directorio,
    Noticias,
    Planeacion,
    Video
  }
})
export default class ServiciosEgresados extends Vue{
  contenidoSection = 0;
  
  constructor(){
    super();
    const elemento: any = document.getElementsByClassName('menu');
    const separador = document.getElementsByClassName('separador');
    let altura: any = 0;
    window.onscroll = function(){
      if(elemento[0].offsetTop != 0){
        altura = elemento[0].offsetTop;
      }
      if (window.scrollY > (altura)) {
          elemento[0].classList.add('menu-h')
          elemento[0].classList.remove('py-4')
          separador[0].classList.add('pt-6')
      } else if (window.scrollY <= (altura)) {
          elemento[0].classList.remove('menu-h')
          elemento[0].classList.add('py-4')
          separador[0].classList.remove('pt-6')
      }
    };
  }
  contenido(pos: number){
    this.contenidoSection = pos;
  }
  //metodo para volver al inicio 
  volverInicio(){
    this.$router.push({ name: "home" });
  }
}
