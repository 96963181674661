
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component
export default class ModificarPerfil extends Vue {
  usuario = {
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    centro_formacion: "",
    correo: "",
    perfil: "",
    telefono: "",
    area_ruta_id: "",
    regional: "",
  };
  areaRuta = {
    id_usuario: null,
    persona_id: null,
    id_area_ruta: null,
    area_ruta: "",
    red_conocimiento: "",
    id_red_conocimiento: null,
  };

  constructor() {
    super();
  }

  mounted() {
    this.obtenerUsuario(this.$route.params.id);
    this.obtenerAreaRuta(this.$route.params.id);
  }
  async obtenerUsuario(id) {
    await Axios()
      .get("listar_usuario/" + id)
      .then((respuesta) => {
        return respuesta.data.results; //pasa a la siguiente promesa
      })
      .then((res) => {
        // if(res){
        setTimeout(() => {
          this.usuario = res;

          // this.usuario = respuesta.data.results;
        }, 100);
        // }
      });
  }

  obtenerAreaRuta(idUsuario) {
    Axios()
      .get("ver_area_red_usuario/" + idUsuario)
      .then((respuesta) => {
        this.areaRuta = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
