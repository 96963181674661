var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-content-between flex-wrap-reverse align-items-center"},[_c('div',{staticClass:"col-sm-6"},[_vm._m(0),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaPaises","list":_vm.listaPaises,"per":2}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Funciones")])])]),_c('tbody',_vm._l((_vm.paginated('listaPaises')),function(paises,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(paises))])])}),0)])])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaPaises","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
          ul: 'simple-links-container',
          li: 'simple-links-item',
          liActive: ['simple-links-item', 'active1'],
          'li.active': 'active1',
        }}})],1)]),_c('img',{staticClass:"col-12 col-md-5 borde mb-5 mb-md-0",attrs:{"src":require("@/assets/bienestar/funciones.jpg"),"alt":""}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-md-12"},[_c('h1',{staticClass:"blod text-azul-sena text-capitalize"},[_vm._v(" Ejecución de la Formación ")]),_c('p',{staticClass:"m-0 p-0 text-justify"},[_vm._v(" Bajo la resolución 0526 están definidas nuestras funciones como grupo. ")])])
}]

export { render, staticRenderFns }