
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import ReporteConvocatoria from "@/components/ReportesBienestar/Regional/ReportesConvocatoriaRegional.vue";
import ReporteAdjudicados from "@/components/ReportesBienestar/Regional/ReporteAdjudicadosRegional.vue";
import ReporteInscritos from "@/components/ReportesBienestar/Regional/ReporteInscritosRegional.vue";
import ReportePresupuesto from "@/components/ReportesBienestar/Regional/ReportePresupuestoRegional.vue";

@Component({
  components: {
    ReporteConvocatoria,
    ReporteAdjudicados,
    ReporteInscritos,
    ReportePresupuesto,
  },
})
export default class IndexReportes extends Vue {
  esReporteConvocatoria = false;
  esReporteAprendices = false;
  esReportePresupuesto = false;

  aprendicesAdjudicados = false;
  aprendicesInscritos = false;

  constructor() {
    super();
  }

  cambiarEstadosComponentes() {
    this.esReporteConvocatoria = false;
    this.esReporteAprendices = false;
    this.esReportePresupuesto = false;

    this.aprendicesAdjudicados = false;
    this.aprendicesInscritos = false;
  }
}
