
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component({
  components: {},
})
export default class Idioma extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  listarIdiomas: any[] = [];
  listarIdiomasString: any[] = [];

  lista_idiomas: any[] = [];

  docValido = false;
  estadoPeticion = false;

  inactivo = false;

  contIdiomaGuardada = 0;

  idiomas = {
    id_idioma_persona: "",
    persona_id: "",
    idioma_id: "",
    idioma: "",
    institucion: "",
    nivel: "",
    certificado: null,
  };

  mounted() {
    this.obtenerIdiomas();
  }

  //-----------------------------------------------------------
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.idiomas.certificado = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
      } else {
        this.docValido = false;
        this.idiomas.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.idiomas.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  //--------------------------------------------------------------------
  // Roa y edier eliminar
  //--------------------------------------------------------------------
  obtenerIdiomas() {
    Axios()
      .get("crear_idiomas_persona")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_idiomas = respuesta.data.results.idiomas;
        } else {
          Swal.fire({
            title: "No se pudo obtener idiomas",
            text: respuesta.data.message,
            icon: "error",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  //--------------------------------------------------------------------
  listarIdiomasPersona() {
    //Creamos el formData
    var data = new FormData();

    //Añadimos la docinscrip seleccionada
    data.append("id_idioma_persona", this.idiomas.id_idioma_persona);
    data.append("persona_id", this.id_persona_logeada);
    data.append("idioma_id", this.idiomas.idioma_id);
    data.append("idioma", this.idiomas.idioma);
    data.append("institucion", this.idiomas.institucion);
    data.append("nivel", this.idiomas.nivel);
    data.append("certificado", this.idiomas.certificado);

    this.listarIdiomas.push(data);
    this.listarIdiomasString.push(this.idiomas);
    this.idiomas = {
      id_idioma_persona: "",
      persona_id: "",
      idioma_id: "",
      idioma: "",
      institucion: "",
      nivel: "",
      certificado: ""
    };
  }
  //--------------------------------------------------------------------
  registrarIdiomasPersona() {
    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (this.listarIdiomas.length != 0) {
      for (var i = 0; i < this.listarIdiomas.length; i++) {
        Axios()
          .post("almecenar_idioma_persona", this.listarIdiomas[i], {
            headers: headers,
          })
          .then((respuesta) => {
            if ((respuesta.data.status = "success")) {
              this.contIdiomaGuardada++;
              if (this.contIdiomaGuardada == this.listarIdiomas.length) {
                Swal.fire({
                  title: "Idiomas Guardados",
                  text: "Se guardaron " + this.contIdiomaGuardada + " Idiomas",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                });
              }
              this.$router.push({ name: "GestionarHojaDeVidaENI" });
              // this.contExpGuardada++;
            } else {
              Swal.fire("ERROR!", "No se Guardaron Sus Idiomas", "error");
            }
            this.estadoPeticion = false;
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    } else {
      Swal.fire({
        title: "Un momento!",
        icon: "info",
        text: "No hay idiomas agregados, por favor llene el formulario al menos una vez",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
      });
    }
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.listarIdiomasPersona();
      }
    });
  }
}
