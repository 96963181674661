
  import { Vue, Component } from "vue-property-decorator";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";

  @Component({
    components: {
    },
  })
  export default class Documentos extends Vue {
    id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

    docValido = false;
    estadoPeticion = false;
    rutadocumento = null;
    verModal = false;

    inactivo = false;

    documentos = {
      id_documento: "",
      persona_id: this.id_persona_logeada,
      ruta: "",
      documento: "",
    }

    mounted() {
      this.obtenerDocs(this.$route.params.id);
      this.people();
    }
    people() {
      this.id_persona_logeada;
    }

    obtenerDocumento(event) {
      if (event.target.files[0].size <= 5000000) {
        //5mb tope
        let extDoc = event.target.files[0].name
          .toString()
          .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
        if (extDoc == ".pdf") {
          this.documentos.ruta = event.target.files[0];
          Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
          this.docValido = true;
          // this.docResolucon = null;
        } else {
          this.docValido = false;
          this.documentos.ruta = null;
          Swal.fire(
            "Algo salió mal!",
            "El archivo cargado no es un PDF!",
            "error"
          );
        }
      } else {
        this.docValido = false;
        this.documentos.ruta = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado pesa más de 5 MegaBytes!",
          "error"
        );
      }
    }

    mostrarDocumento(id_documento) {
      this.rutadocumento =
        this.$urlAPI + "visualizar_documento/" +
        id_documento;
    }

    obtenerDocs(id) {
      Axios()
        .get("ver_detalle_documento/" + id)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.documentos = respuesta.data.results[0];
            this.mostrarDocumento(id);
          }
        })
        .finally(() => {
          this.estadoPeticion = true;
        });
    }

    mirarModal() {
      this.verModal = true;
    }

    modificarDocumento() {
      this.estadoPeticion = true;
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      var data = new FormData();
      data.append("persona_id", this.id_persona_logeada);
      data.append("ruta", this.documentos.ruta);
      data.append("documento", this.documentos.documento);
      Axios()
        .post("modificar_documento/" + this.documentos.id_documento, data, {
          headers: headers,
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {

            Swal.fire({
              title: "Documento Guardado",
              text: "Se Guardo el Documentos",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });

            this.$router.push({ name: "GestionarDocumento" });
          } else {
            Swal.fire({
              title: "No se Guardo el Documento",
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          }
        })
        .catch((respuesta) => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }

    validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.modificarDocumento();
        }
      });
    }
    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          date_format: () => "Campo obligatorio",
          decimal: () => "Campo obligatorio",
          min_value: () => "No pude ser negativo",
          alpha_spaces: () => "No permite numeros ni caracteres especiales",
          alpha_num: () => "No permite caracteres especiales",
        },
      };
      this.$validator.localize("es", dict);
    }
  }
