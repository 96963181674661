
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component
export default class ModificarPerfil extends Vue {
  usuario = {
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    centro_formacion: "",
    id_centro_formacion: "1",
    correo: "",
    perfil: "",
    id_perfil: "1",
    telefono: "",
  };
  listaCentros = [];
  perfiles;

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        // min: () => "Mínimo 8 caracteres",
        email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerUsuario(this.$route.params.id);
    this.obtenerPerfiles();
  }
  async obtenerUsuario(id) {
    await Axios()
      .get("/editar_usuario/" + id)
      .then((respuesta) => {
        return respuesta.data.results; //pasa a la siguiente promesa
      })
      .then((res) => {

        // if(res){
        setTimeout(() => {
          this.usuario = res.usuario;
          this.perfiles = res.perfiles;
          this.listaCentros = res.centros_formacion;

          // this.usuario = respuesta.data.results;
        }, 100);
        // }
      });
  }

  obtenerPerfiles() {
    Axios()
      .get("listar_perfiles")
      .then((respuesta) => {
        this.perfiles = respuesta.data.results;

        // this.$router.push({name: "dashboard"})
      })
      .catch((respuesta) => {
        //  Swal.fire((respuesta.data.status+"!").toString().toUpperCase(), respuesta.data.message, respuesta.data.status);
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor al cargar perfiles!",
          "error"
        );
      });
  }

  ModificarUsuario() {
    Axios()
      .put("/actualizar_usuario/" + this.$route.params.id, this.usuario)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Usuario Modificado!".toString().toUpperCase(),
            "Se a modificado el usuario exitosamente",
            respuesta.data.status
          );
          this.$router.push({ name: "GestionarUsuario" });
        } else {
          Swal.fire(
            (respuesta.data.status + "!").toString().toUpperCase(),
            respuesta.data.message.usuario,
            respuesta.data.status
          );
        }
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.ModificarUsuario();
      }
    });
  }
}
